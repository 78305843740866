<template>
    <div
        v-if="companyUserProgramTask.isLoaded()"
        class="wrapper"
    >
        <UserRequirementCategoryEdit
            :requirement-category="requirementCategory"
            :user-id="companyUserSurveyQuestionOwner"
            :is-participant="true"
            :is-modal="true"
            :editable="true"
            :signer-task="companyUserProgramTask"
            :is-related-task-executor="true"
            :can-update="true"
            @addGroup="onAddGroup"
            @removeGroup="onRemoveGroup"
            @onChangeValue="handleUpdateRequirement"
            @on-update-requirement="handleUpdateRequirement"
        />
        <HtButton
            v-if="isLastMessage"
            :loading="loading"
            :disabled="!canSave"
            @click.native="onClick()"
        >
            <t>Save</t>
        </HtButton>
    </div>
</template>

<script>
import UserRequirementCategoryEdit from '@/components/pages/onboarding/UserRequirementCategoryEdit.vue';
import HtButton from '@/components/globals/HtButton.vue';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';
import CompanyResourceProgramParticipant from '@/models/CompanyResourceProgramParticipant';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import get from 'lodash.get';
import api from '@/store/api';
import CompanyUserRequirement from '@/models/CompanyUserRequirement';
import UserRequirementEdit from '@/components/pages/onboarding/UserRequirementEdit.vue';
import MessageResourceUserSurveyQuestionMixin from './mixin/MessageResourceUserSurveyQuestionMixin';

export default {
    name: 'MessageResourceUserSurveyQuestionRequirement',

    components: {
        UserRequirementEdit,
        UserRequirementCategoryEdit,
        HtButton,
    },
    mixins: [
        MessageResourceUserSurveyQuestionMixin,
    ],

    data() {
        return {
            categoryId: null,
            categoryUpdated: false,
            companyUserProgramTask: new CompanyUserProgramTask([
                'id',
                'status',
                'resource',
                'resource_id',
                'company_user_id',
            ]).where([
                ['resource', '=', CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY],
            ]),
            loading: true,
            savingEnabled: false,
        };
    },

    computed: {
        requirementCategory() {
            return this.$store.getters['requirementCategoryEditModal/category'](this.categoryId) || {};
        },

        /**
         * This function is only usefull as long as the following request return results.
         * Its used to allow user to generate the answer that follow a requirement question when the
         * requirement has been completed outside of the survey which is an anormal state and is no longer possible.
         * the sql request that should show all impacted users :
         *
         * SELECT * FROM company_user_survey_question a
         *  JOIN company_survey_question b ON a.company_survey_question_id = b.id
         *  JOIN company_requirement_category c ON b.resourceable_id = c.id
         *  JOIN company_user_requirement_category d ON d.company_requirement_category_id = c.id AND d.company_user_id = a.company_user_id
         *  WHERE a.status = 'pending'
         *  AND b.resourceable_type LIKE "%CompanyRequirementCategory%"
         *  AND d.status = 'done';
         *
         * If you pass here ad show that, check for result existence and if there is no relevant one, remove this function and the one above
         * and load only pending requirements.
         */
        isLastMessage() {
            return this.$parent.$parent.$parent.$children.indexOf(this.$parent.$parent) === this.$parent.$parent.$parent.$children.length - 1;
        },

        canSave() {
            this.savingEnabled = this.isSavingEnabled();
            return this.savingEnabled;
        },

        isTaskDone() {
            const getTasks = get(this.requirementCategory, 'tasks', []);

            const taskExecutor = getTasks.find((task) => task.participants[0].type === 'executor' && task.participants[0].company_user_id === this.shared.session.companyUser.id);

            const status = get(taskExecutor, 'status', 'pending');

            return status === 'done';
        },
    },

    unmounted() {
        this.$store.dispatch('requirementCategoryEditModal/stop');
    },

    async created() {
        this.companyUserProgramTask.whereIn([
            ['status', [CompanyUserProgramTask.STATUS_PENDING, CompanyUserProgramTask.STATUS_DONE]],
        ])
            .whereHas({
                companyUserRequirementCategory: (query) => {
                    query.where([
                        ['display_survey', '=', true],
                        ['company_requirement_category_id', '=', this.companySurveyQuestion.resourceable_id],
                        ['company_user_survey_id', '=', this.companyUserSurveyQuestion.company_user_survey_id],
                    ]);
                },
                participants: (query) => {
                    query.where([
                        ['type', '=', CompanyResourceProgramParticipant.TYPE_EXECUTOR],
                        ['company_user_id', '=', this.companyUserSurveyQuestion.company_user_participant_id],
                    ]);
                },
            })
            .where([
                ['company_user_id', '=', this.companyUserSurveyQuestionOwner],
            ]);

        await this.companyUserProgramTask.get();

        api.user.requirementCategories.get(
            this.companyUserSurveyQuestionOwner,
            this.companyUserProgramTask.resource_id,
        )
            .then((res) => {
                this.loading = false;
                this.categoryId = res.data.data.id;
                if (!this.$store.getters['requirementCategoryEditModal/isStarted']) {
                    this.$store.dispatch('requirementCategoryEditModal/start');
                }
                this.$store.dispatch('requirementCategoryEditModal/addCategory', res.data.data);
            });
    },

    methods: {
        /** @returns {void} */
        handleUpdateRequirement() {
            this.savingEnabled = this.isSavingEnabled();
        },
        /** @returns {Boolean} */
        isSavingEnabled() {
            if (!this.requirementCategory || this.isTaskDone) {
                return false;
            }

            if (this.requirementCategory.is_mandatory) {
                const hasEveryMandatoryRequirementDone = this.requirementCategory.groups.every(
                    (group) => {
                        const mandatoryRequirements = this.getMandatoryRequirements(group);
                        if (!this.allRequirementsMustBeDone(mandatoryRequirements)) {
                            return false;
                        }

                        const lastRequirement = this.getTheLastRequirementOfTheCategory(mandatoryRequirements);
                        if (this.isLastRequirementFileToFillWithSignAfterCompletion(lastRequirement)) {
                            return false;
                        }

                        return true;
                    },
                );

                return hasEveryMandatoryRequirementDone;
            }

            return true;
        },
        /**
         * @typedef {
         *      id: Number,
         *      type: String,
         *      name: String,
         *      status: String,
         * } Requirement
         * @typedef {
         *     id: Number,
         *     requirements: Requirement[]
         * } Group
         * @param {Group} group
         * @returns {Requirement[]}
         */
        getMandatoryRequirements(group) {
            return group.requirements.filter(
                (requirement) => Boolean(requirement.is_mandatory) === true,
            );
        },
        /**
         * @param {Requirement[]} mandatoryRequirements
         * @returns {Boolean}
         */
        allRequirementsMustBeDone(mandatoryRequirements) {
            return mandatoryRequirements.every(
                (requirement) => requirement.status === CompanyUserRequirement.STATUS_DONE,
            );
        },
        /**
         * @param {Requirement[]} mandatoryRequirements
         * @returns {Requirement}
         */
        getTheLastRequirementOfTheCategory(mandatoryRequirements) {
            return mandatoryRequirements[mandatoryRequirements.length - 1];
        },
        /**
         * @param {Requirement} lastRequirement
         * @returns {Boolean}
         */
        isLastRequirementFileToFillWithSignAfterCompletion(lastRequirement) {
            return lastRequirement.type === CompanyUserRequirement.TYPE_FILE_TO_FILL
                && lastRequirement.sign_after_complete === 1;
        },
        onAddGroup(group) {
            this.$store.dispatch('requirementCategoryEditModal/addGroup', {
                categoryId: this.categoryId,
                group,
            });
        },

        onRemoveGroup(groupId) {
            this.$store.dispatch('requirementCategoryEditModal/removeGroup', {
                categoryId: this.categoryId,
                groupId,
            });
        },

        async onClick() {
            if (!this.isTaskDone && this.loading === false) {
                const isValid = await this.$validator.validateAll();

                if (isValid) {
                    this.loading = true;

                    api.user.requirementCategories.update(
                        this.companyUserSurveyQuestionOwner,
                        this.requirementCategory.id,
                        {
                            resource: this.requirementCategory,
                            taskId: this.companyUserProgramTask.id,
                        },
                    )
                        .then(() => {
                            if (this.categoryUpdated === false) {
                                this.categoryUpdated = true;

                                const companyUserSurveyQuestionAnswerCollection = new CompanyUserSurveyQuestionAnswerCollection([
                                    'resourceable_id',
                                    'resourceable_type',
                                ]);

                                const answer = companyUserSurveyQuestionAnswerCollection.new();
                                answer.resourceable_id = this.companyUserProgramTask.resource_id;
                                answer.resourceable_type = CompanyRequirementCategory.RESOURCEABLE_CLASS;

                                companyUserSurveyQuestionAnswerCollection.models.push(answer);

                                this.$emit('onAnswers', companyUserSurveyQuestionAnswerCollection);
                            }

                            this.loading = false;
                        });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: $color-lightblue-grey;
}
</style>
