import Vue from 'vue';

/**
 * @typedef {Object} UserToConnect
 * @property {string} code
 * @property {string} email
 * @property {string} password
 * @property {string} domain
 * @property {string|undefined} type
 * @property {string|undefined} conversation
 */

/**
 * @typedef {Object} FailedLoginResponse
 * @property {string} message
 * @property {number|undefined} password_attempts
 * @property {number|undefined} password_attempts_max
 */

/**
 * @typedef {Object} SucceedLoginResponse
 * @property {string} domain
 * @property {User} company_user
 */

/**
 * @typedef {{data: {data: FailedLoginResponse|SucceedLoginResponse}}} LoginResponse
 */

export default {
    /**
     *
     * @param {UserToConnect} user
     * @returns {Promise<LoginResponse>}
     */
    login(user) {
        return Vue.prototype.$http.post('auth/login', user);
    },

    /**
     * @param {string} code
     * @param {string} user
     * @param {string} email
     * @returns {Promise<LoginResponse>}
     */
    checkTwoFactorAuthCode(code, email, domain) {
        return Vue.prototype.$http.post(`auth/check-two-factor-auth-code/${code}`, { email, domain });
    },

    resendTwoFactorAuthCode(email, domain) {
        return Vue.prototype.$http.post('auth/resend-two-factor-auth-code', { email, domain });
    },
};
