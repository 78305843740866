import CompanyUserRequirement from '@/models/CompanyUserRequirement';
import api from '@/store/api/user/requirementCategories';

export default {
    namespaced: true,

    state() {
        return {
            is_used: false,
            requirementCategories: [],
        };
    },

    getters: {
        category: (state) => (id) => state.requirementCategories.find((cat) => cat.id === id),

        hasDynamicDocument: (state) => (categoryId) => {
            if (state.requirementCategories.find((cat) => cat.id === categoryId).groups) {
                return state.requirementCategories.find((cat) => cat.id === categoryId).groups[0]?.requirements.some(
                    (requirement) => requirement.type === CompanyUserRequirement.TYPE_DYNAMIC_DOCUMENT,
                );
            }
        },

        isStarted: (state) => state.is_used,

        requirement: (state) => (requirementId) => state.requirementCategories
            .flatMap((requirementCategory) => requirementCategory.groups)
            .flatMap((group) => group.requirements)
            .find((currentRequirement) => currentRequirement.id === requirementId),

        getRequirementWithSignAfterCompleteAttempt: (state) => state.requirementCategories
            .flatMap((requirementCategory) => requirementCategory.groups)
            .flatMap((group) => group.requirements)
            .find(
                (currentRequirement) => currentRequirement.type === CompanyUserRequirement.TYPE_FILE_TO_FILL
                    && currentRequirement.sign_after_complete === 1,
            ),
    },

    actions: {
        addGroup({ commit }, params) {
            commit('add_group', params);
        },

        removeGroup({ commit }, params) {
            commit('remove_group', params);
        },

        addCategory({ commit }, category) {
            commit('add_category', category);
        },

        /**
         * @param {Object} params The necessary parameters to load the category
         * @param {number} params.userId The user id
         * @param {number} params.categoryId The category id
         */
        async loadCategory({ commit }, params) {
            const response = await api.get(params.userId, params.categoryId);
            commit('add_category', response.data.data);
        },

        updateRequirement({ commit, getters }, requirement) {
            // getting the current stored requirement
            let storedRequirement = getters.requirement(
                requirement.id,
            );

            if (!storedRequirement) {
                storedRequirement = getters.getRequirementWithSignAfterCompleteAttempt;
            }

            // no need to update when no requirement has been found
            if (!storedRequirement) {
                return;
            }

            commit('update_requirement', { storedRequirement, requirement });
        },

        start({ commit }) {
            commit('start');
        },

        stop({ commit }) {
            commit('stop');
        },
    },

    mutations: {
        add_group(state, params) {
            const {
                categoryId,
                group,
            } = params;
            state.requirementCategories.find((cat) => cat.id === categoryId)
                .groups
                .push(group);
        },

        remove_group(state, params) {
            const {
                categoryId,
                groupId,
            } = params;
            const index = state.requirementCategories.find((cat) => cat.id === categoryId)
                .groups
                .findIndex((group) => group.id === groupId);
            state.requirementCategories.find((cat) => cat.id === categoryId)
                .groups
                .splice(index, 1);
        },

        add_category(state, category) {
            const existing = state.requirementCategories.findIndex((cat) => cat.id === category.id);
            if (existing >= 0) {
                state.requirementCategories.splice(existing, 1);
            }
            state.requirementCategories.push(category);
        },

        start(state) {
            state.is_used = true;
        },

        stop(state) {
            state.is_used = false;
        },

        update_requirement(state, {
            storedRequirement,
            requirement,
        }) {
            // warning: shouldn't we replace the entire requirement?
            storedRequirement.value = requirement.value;
            storedRequirement.status = requirement.status;
            storedRequirement.type = requirement.type;
            storedRequirement.sign_after_complete = requirement.sign_after_complete;
            storedRequirement.company_file = requirement.company_file;
            storedRequirement.company_file_id = requirement.company_file_id;
            storedRequirement.company_file_secondary = requirement.company_file_secondary;
            storedRequirement.company_file_secondary_id = requirement.company_file_secondary_id;
        },
    },
};
